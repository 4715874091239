$henley-blue: #405363;
$henley-gray: #75767A;
$secondary-gray: #C1C5C8;
$light-gray: #C5C6C8;
$gray: #6F7072;
$dark-gray: #4B4B4D;
$background-colour: #f7f7f7;
$mobile-max: 1359px;
$mobile-min: 1360px;

.card{
    background-color: #fff;
    padding:15px;
    position: relative;
}

.card.popup{
    @media (min-width:800px){
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 80%;
        margin: 0 auto;
    }

    @media (min-width:1200px){
        &.popupFlowCard{
            width: 60%;
        }
    }
}

.detailImageWrapper{
    position:relative;
    width: 100%;
    padding-bottom: 75%;

    img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 500px;
    }
}

.closeButton{
    position: absolute;
    width: 22px;
    height: 22px;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;

    svg {
        overflow: visible;
    }
}

.rankLabel{
    background-color: #f7f7f7;
    color: var(--color-app-dark-gray);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    line-height: 1;
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 3px 10px;

    @media (min-width:800px){
        top: 0;
        right: 45px;
    }
}

.rankNumber{
    font-size: 26px;
    margin-right: 4px;
    sup{
        font-size: 14px;
    }
}

.rankLabelBottom{
    font-size: 10px;
}

.countryName{
    display: flex;
    align-items: center;
}

.countryNameText{
    margin-left: 10px;
}

.value{
    font-size: 30px;

    @media (max-width:1800px){
        font-size: 22px;
    }

    @media (min-width:800px) and (max-width:991px){
        font-size: 17px;
    }
}

.hwni{
    font-size: 20px;
    line-height: 1;
    margin-bottom: 0;

    @media (max-width: 799px) {
        font-size: 16px;
        //margin-bottom: -15px;
        margin-bottom: auto;
    }
}

.countryImageWrapper {
    @media (min-width: 800px) {
        width: 100%;
        height: 100%;
        //padding-bottom: 10px;
        img {
            max-width: none;
            width: 100%;
        }
    }
    @media (min-width: 1200px) {
        width: 100%;
    }
}

.shadowBackground {
    background-color: var(--color-app-henley-blue);
    svg{
        color: white!important;
    }
}

.card.wealthpopup{

    @media (max-width: 799px) {
        width: auto;
        display:none;
    }
    @media (min-width:800px){
        width: 70%;
        position: fixed;

        top: 50%;
        left: 50%;
        right: 0;
        transform: translate(-50%, -50%);
        //transform: translateX(-50%);
        margin: 0 auto;

    }

    &.popupWealthFlowCard{
        img {
            max-width: none;
            width: 100%;
        }
    }

    @media (min-width:1200px){
        &.popupWealthFlowCard{
            width: 90%;
            max-width: 1800px;
            .colPopup {
                display: flex;
            }
            .rowImage {
                width: 50%;
                margin-right: 0;
                padding-right: 24px;
                img {
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
            .rowContent {
                width: 50%;
                padding-right: 0 !important;
            }
        }
    }
}

.header {
    margin: 0 0 10px;
    font-size: 36px;
    line-height: 1.1666666667em;
    @media screen and (max-width: 799px) {
        font-size: 18px;
        line-height: 24px;
    }
}

.headerH2 {
    //padding-left: 10px;
    margin-bottom: -10px;

    @media screen and (max-width: 799px) {
        margin-bottom: 10px;
    }
}

.headerScore {
    font-family: Roboto Regular;
    font-weight: bold!important;
    font-size: 11px!important;
    color: var(--color-app-henley-blue);

    @media screen and (max-width: 799px) {
        padding-bottom: 20px;
    }
}


.outOf60 {
    display: block;
    //font-size: 14px;
    @media screen and (max-width: 799px) {
        min-width: 60px;
        //font-size: 10px;
    }
}

.scoreField {
    color: #75767A;
}


.col8p5{
    //flex: 0 0 70%;
    max-width: 70% !important;
    max-height: 30px;
    //margin-left: 15px;

    @media (max-width: 799px) {
        max-height: 60px;
    }
}

.col3p5{
    flex: 0 0 27%;
    max-width: 27%;
    @media (max-width: 799px) {
        max-height: 60px;
    }

}

.hiddenMobile{
    @media (max-width: 799px) {
        display: none!important;
    }
}

.hiddenDesktop{
    @media (min-width: 900px) {
        display: none!important;
    }

}
