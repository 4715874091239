$henley-blue: #405363;
$henley-gray: #75767A;
$secondary-gray: #C1C5C8;
$light-gray: #C5C6C8;
$gray: #6F7072;
$dark-gray: #4B4B4D;
$background-colour: #f7f7f7;
$mobile-max: 799px;
$mobile-min: 800px;
.cryptoInvestmentMigration {
  .cryptoInvestmentMigrationTable {
    color: $henley-blue;
    width: 100%;

    tr {
      border-bottom: 1px solid $secondary-gray;
      @media screen and (max-width: $mobile-max) {
        border: none;
      }
    }

    .cryptoInvestmentMigrationTableHead th {
      font-weight: normal;
      vertical-align: bottom;

      .cryptoInvestmentMigrationTableHeader {
        margin: 0 0 10px;
        font-size: 36px;
        line-height: 1.1666666667em;
        @media screen and (max-width: $mobile-max) {
          font-size: 18px;
          line-height: 24px;
        }
      }

      .cryptoInvestmentMigrationTableHeaderH2 {
        padding-left: 10px;
        margin-bottom: 10px;
      }

      .cryptoInvestmentMigrationTableHeaderScore {
        font-family: Roboto Regular;
        font-weight: bold;
        font-size: 11px;
        @media screen and (max-width: $mobile-max) {
          //font-size: 14px;
          //margin-left: -40px;
        }
      }
    }

    th.totalScore {
      color: var(--color-app-henley-gray);
      vertical-align: bottom;
      text-align: right;
      min-width: 110px;

      .outOf60 {
        display: block;
        font-size: 14px;
      }

      @media screen and (max-width: $mobile-max) {
        min-width: 90px;


        .outOf60 {
          font-size: 10px;
        }
      }
    }

    th.percentage {
      text-align: right;
      padding-right: 20px;
      vertical-align: bottom;
    }


    // data table
    td.iconColumn {
      width: 70px;
      padding-right: 30px;
      @media screen and (max-width: $mobile-max) {
        min-width: 42px;
        max-width: 42px;
        padding-right: 16px;
        img {
          margin-top: 5px;
        }
      }
    }

    td.nameColumn {
      width: 180px;
      padding-right: 30px;
      font-size: 18px;
      @media screen and (max-width: $mobile-max) {
        display: none;
      }
    }

    td.barchartColumn {
      position: relative;
      @media screen and (max-width: $mobile-max) {
        width: 95%;
      }

      .barchartBackground,
      .barchartForeground {
        position: absolute;
        left: 0;
        top: 19px;
        height: 20px;
        border-radius: 10px;
        //background: #fff;
        background:inherit;

        @media screen and (max-width: $mobile-max) {
          height: 12px;
          top: 16px;
        }
      }

      .barchartBackground {
        width: 100%;
      }

      @keyframes slidein {
        from {
          max-width: 0px;
          opacity: 0;
        }
        to {
          max-width: 100%;
          opacity: 1;
        }
      }

      .barchartForeground {
        overflow: hidden;
        background: $henley-blue;
        width: 0%;
        animation: slidein 700ms;
      }

      .barChartLabel {
        margin: 28px 0 0;
        font-size: 14px;
        @media screen and (min-width: $mobile-max) {
          display: none;
        }
        @media screen and (max-width: $mobile-max) {
          display: block;
        }
      }
    }

    @keyframes appear {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    td.scoreColumn {
      width: 60px;
      color: $henley-gray;
      text-align: right;
      font-family: Garamond Regular;
      font-weight: 400;
      font-size: 26px;
      line-height: 26px;
      padding: 16px 0 16px 30px;
      animation: appear 1s;
      @media screen and (max-width: $mobile-max) {
        width: 40px;
      }
    }

    @media screen and (max-width: $mobile-max) {
      .cryptoInvestmentMigrationTableHeaderH3,
      .cryptoInvestmentMigrationTableHeaderH2,
      td.scoreColumn {
        font-size: 17px;
      }
      td.scoreColumn {
        line-height: 17px;
        padding: 0 0 5px 30px;
      }
    }
  }
}

.henleyHeaderTitle{
  line-height: 1;
}

.noHenleyBorderBottom {
  border-bottom: none !important;

  @media screen and (min-width: $mobile-min) {
    display: none;
  }
}

.henleyHeaderDesktop {

  @media screen and (max-width: $mobile-max) {
    display: none;
  }
}