$henley-blue: #405363;
$henley-gray: #75767A;
$secondary-gray: #c1d3e0;
$light-gray: #C5C6C8;
$lightest-gray: #f7f7f7;
$gray: #6F7072;
$dark-gray: #4B4B4D;
$background-colour: #f7f7f7;
$white: #fff;

$gray-3: #C1C5C8;
$gray-4: #DFE1E2;
$gray-5: #f7f7f7;