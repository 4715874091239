$mobile-max: 799px;
$mobile-min: 800px;
//$mobile-max: 1199px;
//$mobile-min: 1200px;

h1 {
    font-size: 40px;
    @media (max-width: $mobile-max){
        font-size: 32px;
    }

}

.table {
    width: 800px;
    @media (max-width: $mobile-max){
        width: 100%;
        max-width: 800px;
    }
    th,td{
        border-bottom: 1px solid #C1C5C8;
        border-right: 1px solid #C1C5C8;
        padding: 8px;
        &:last-child {
            border-right: none;
        }
        &.noBorderRight {
            border-right: none;
        }
        &.selectedColumn {
            @media (max-width: $mobile-max){
                background: #F7F7F7;
            }
        }
        &.colHeader{
            font-weight: 800!important;
        }
    }

    th{
        font-size: 14px;
        line-height: 18px;
        color: var(--color-app-henley-blue);
        font-weight: 400;
        //white-space: nowrap;
        vertical-align: bottom;
        &.headerColumn {
            font-weight: 500;
            padding: 10px 8px;
            text-align: center;
        }
        &.dataColumn {
            width: 200px;
            @media (max-width: $mobile-max){
                width: 50%;
            }
        }
    }
    tr {
        td{
            font-size: 17px;
            line-height: 20px;
            height: 60px;
            font-family: Garamond Regular;
            color: var(--color-app-henley-blue);
            @media (min-width: $mobile-min) {
                font-size: 20px;
                line-height: 24px;
            }
            &.dataColumn {
                width: 200px;
                @media (max-width: $mobile-max){
                    width: 50%;
                }
            }
        }

        &.borderBottomDashed{
            td{
                border-bottom-style:dashed;
            }
        }
    }
}

.emptyColumn {
    width: 1%;
}

.sortTitle {
    font-size: 12px;
    font-family: "Roboto Regular";
    margin-bottom: 15px;
}


.cellHideMobile{
    @media (min-width: $mobile-min){
        &.colHeader{
            display: none;
        }
    }
    @media (max-width: $mobile-max){
        display: none;

        &.selectedColumn{
            display: table-cell;
        }
    }
}


.showMobile{
    &.colHeader{
        background: #F7F7F7;
        font-weight: 700!important;
        >svg {
            width: 40px;
            height: 40px;
        }
    }
    @media (max-width: $mobile-max){
        display: none;
    }

}


.sortMobileWrapper{
    margin-bottom: 20px;
}

.hiddenTableRow{
    @media (min-width: $mobile-min){
        display: none;
    }
}


.cryptoWealthChartTable{
    max-width: 1200px;
    svg {

        width: 18px;
        height: 18px;
        margin-right: 6px;
    }
    th {
        @media (max-width: $mobile-max){
            border-right: none;
        }
    }
    .contentRow {
        td.headerColumn{
            @media (max-width: $mobile-max){
                min-width: 40%;
            }
        }
        //td.totalCrypto {
        //    font-weight: bold;
        //}
        @media (max-width: $mobile-max){
            cursor: pointer;
        }
        &.spacer {
            td {
                padding: 0;
                height: 20px !important;
            }
        }
        //&.totalRow {
        //    td{
        //        font-weight: bold;
        //    }
        //}
    }
    margin-bottom: 32px;
}

.largeSvg {
    color: red;
    svg {
        width: 40px!important;
        height: 40px!important;
    }
}

.linkText{
    color: inherit;
    text-decoration: underline;
    font-size: 16px;
}

.footerNote {
    font-size: 12px;
    p {
        font-size: 12px;
    }
    ul {
        margin: 0 0 16px 0;
        padding: 0;
        li {
            font-size: 12px;
            margin: 0 0 2px 16px;
            padding: 0;
            list-style: disc;
            &:before {
                display: none;
            }
        }
    }
}

.legend {
    display: flex;
    align-items: center;
    .legendBox {
        width: 12px;
        height: 12px;
        display: inline-block;
        margin-right: 8px;

        &.darkBlue {
            background: #405363;
        }

        &.lightBlue {
            background: #6EA7D8;
        }
    }
}

.displayFlex {
    display: flex;
}

.noBottomBorder {
    border-bottom: none!important;
}