@import '../../../assets/scss/variable.scss';

.share_label{
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 20px;

  .label_icon{
    width: 16px;
    margin-right: 12px;
    display: inline-block;
  }

  .label_text{
    font-family: "Roboto Bold";
    font-size: 11px;
    letter-spacing: 1.2px;
    line-height: 1.0;
    text-transform: uppercase;
    color: #75767A;

    // padding-top: 4px;
  }
}

.share_icon{
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
  flex: 0 0 auto;
  position: relative;
  // background: red;
  svg{
    width: 100%;
    height: 100%;
  }
  &:last-of-type {
    margin-right: 0;
  }
}

.tooltip{
  width: 92px;
  background-color: $gray-5;
  border: 1px solid $gray-3;
  text-align: center;
  padding: 11px;
  z-index: 1;
  font-size: 12px;
  font-family: 'Roboto Medium';
  line-height: 14px;
  color: $henley-blue;
  position: absolute;
  bottom: 210%;
  left: -50%;
  margin-left: -26px;
  box-shadow: 0 0 16px 0 rgba(0,0,0,0.20);
  &:before,
  &:after{
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
  }

  &:before{
    margin-left: -14px;
    // border-width: 19px;
    // border-style: solid;
    // border-color: $gray-3 transparent transparent transparent;
    border-top: 19px solid $gray-3;
    border-right: 14px solid transparent;
    // border-bottom: 19px solid transparent;
    border-left: 14px solid transparent;
  }

  &:after{
    margin-left: -13px;
    // border-width: 18px;
    // border-style: solid;
    // border-color: $gray-5 transparent transparent transparent;
    border-top: 18px solid $gray-5;
    border-right: 13px solid transparent;
    // border-bottom: 18px solid transparent;
    border-left: 13px solid transparent;
  }
}
