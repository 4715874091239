.countryFlag{
    display: flex;
    flex-direction: row;
    align-items: center;
    .name {
        display: flex;
        flex-direction: column;
    }
    .countryName {
    }
    .regionName {
        font-family: "Roboto Regular";
        font-size: 14px;
    }
    img{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 8px;
        border: 1px solid #c1c5c8;
    }
}

.cellHideDesktop{
    display: none;
    @media (max-width: 799px){
        display: block;
    }
}